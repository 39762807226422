import React, {useState} from 'react'
import Header from '../components/header';
import SolosecVideoModal from '../components/SolosecVideoModal';
import logo_lupin from '../assets/images/logo-lupin.svg';
import landing_footer_swoosh from '../assets/images/landing-footerSwoosh.png';
import map from '../assets/images/map.svg';
import soheil from '../assets/images/soheil.svg';
import andrew from '../assets/images/andrew.svg';
import shane from '../assets/images/shane.svg';
import jerry from '../assets/images/jerry.svg';
import checkmark from '../assets/images/checkmark.svg';
import checkmarkBold from '../assets/images/Checkmark-bold.svg';
import Girls from '../assets/images/Girls.svg';
import GirlsText from '../assets/images/GirlsText.svg';
import ClinicalCureRate from '../assets/images/ClinicalCureRate.svg';
import Aldehyde from '../assets/images/Aldehyde.png';
import mechanismsVideo from '../assets/images/mechanisms-video-thumbnail.svg';
import One from '../assets/images/one.png';
import Two from '../assets/images/two.png';
import Three from '../assets/images/three.png';
import openPacket from '../assets/images/open-packet.svg';
import stirMix from '../assets/images/stir-mix.svg';
import Consume from '../assets/images/consume.svg';
import Jar from '../assets/images/Jar.svg';
import solosecGranules from '../assets/images/solosec-granules.svg';
import surveyImg1 from '../assets/images/9outof10.svg';
import surveyImg2 from '../assets/images/8outof10.svg';
import insightsVideo from '../assets/images/insights-video-thumbnail.svg';
import greyCurve from '../assets/images/grey-curve.svg';
import blueCurve from '../assets/images/blue-curve.png';


export default function NorthEast() {

    const [show, setShow] = useState({ show: false, type: 'alcohol' });
    const handleClose = () => setShow({ show: false, type: 'alcohol' });
    const handleShow = (type) => setShow({ show: true, type: type });

    return (
        <div className="page-wrapper north-east-page">
		<Header
                logoURL="https://www.solosechcp.com/?utm_source=relevate&utm_medium=landing-page&utm_campaign=northeast"
                hcpURL="https://www.solosechcp.com/?utm_source=relevate&utm_medium=landing-page&utm_campaign=northeast"
                isiURL="https://www.solosechcp.com/#isi?utm_source=relevate&utm_medium=landing-page&utm_campaign=northeast"
                preInfoURL="https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=551e43d5-f700-4d6e-8029-026f8a8932ff&type=display"
            />
		<main>
			<h1 className="solosec-main-heading">#1 Prescribed Branded BV-Indicated Treatment<sup><span className="star">*</span></sup></h1>
			<p className="solosec-sub-heading">*Supported by June 2020 IQVIA Data<sup>1</sup></p>
			<div className="banner-wrapper">
                <div className="container">
                    <h2 className="banner-heading">Solosec<sup>&reg;</sup> (secnidazole)</h2>
                    <p className="banner-sub-heading">One Packet. One Dose. One Time.</p>
                    <div className="affected-statistics-wrapper">
                        <h3 className="affected-statistics-heading">Over 21 million</h3>
                        <p className="affected-statistics-subtext">women affected by bacterial vaginosis (BV) in the United States<sup>2</sup></p>
                        <div className="calculated-bv-wrapper">
                            <div className="calculated-bv-wrapper-left">
                                <h4 className="calculated-bv-heading">4,300,000</h4>
                                <p className="calculated-bv-text">women aged 14-49 years in the <br/>Northeast are currently estimated to have BV<sup>2,3</sup><span className="star">*</span></p>
                            </div>
                            <div className="calculated-bv-wrapper-right">
                                <h4 className="calculated-bv-heading">29.2%</h4>
                                <p className="calculated-bv-text">estimated national prevalence <br/>rate of BV<sup>2</sup></p>
                            </div>
                        </div>
                        <div className="estimated-bv-img-wrapper">
                            <img src={map} alt="map" />
                            <div className="estimated-bv-wrapper">
                                <p className="estimated-bv-text">Estimated Bacterial Vaginosis Prevalence (Females Aged 14-49 Years)</p>
                                <div className="estimated-range-wrapper">
                                    <span>40,221</span>
                                    <span>1,357,736</span>
                                </div>
                            </div>
                        </div>
                        <p className="calculated-bv-note">*Calculated by applying 29.2% to the estimated population of females aged 14-49 years in the Northeast.</p>
                    </div>
                </div>
			</div>
            <div className="insights-main">
                <div className="container insights-main-container">
                    <h4 className="insights-heading">Insights From Your Peers<sup><span className="star">*</span></sup></h4>
                    <ul className="insights-list">
                        <li className="insights-list-item">
                            <div className="insights-list-wrapper">
                                <img className="peer-image img-fluid" src={soheil} alt="soheil" />
                                <address className="peer-address">
                                    <span className="font-bold">Soheil Hanjani, MD</span><br/> 
                                    FACOG, FACS<br/>
                                    Hanjani PC<br/>
                                    Brockton, MA
                                </address>
                            </div>
                            <p className="peer-description">I prescribe Solosec because it is the only one-time oral dose product shown to be effective in clinical studies.</p>
                        </li>
                        <li className="insights-list-item">
                            <div className="insights-list-wrapper">
                                <img className="peer-image img-fluid" src={andrew} alt="andrew" />
                                <address className="peer-address">
                                    <span className="font-bold">Andrew M. London, MD</span><br/> 
                                    MBA<br/>
                                    Assistant Professor, Obstetrics and Gynecology<br/>
                                    The Johns Hopkins School of Medicine<br/>
                                    Lutherville, MD
                                </address>
                            </div>
                            <p className="peer-description">One reason patients stop taking their BV treatment is that they want to drink alcohol during the 5- or 7-day course of treatment. I prescribe Solosec for these patients because it is a one-time oral dose with no alcohol restriction.</p>
                        </li>
                        <li className="insights-list-item">
                            <div className="insights-list-wrapper">
                                <img className="peer-image img-fluid peer-image-shane" src={shane} alt="shane" />
                                <address className="peer-address">
                                    <span className="font-bold">Shane Sopp, MD</span><br/> 
                                    FACOG, CCD<br/>
                                    Urogynecology<br/>
                                    Director, Women’s Incontinence Center<br/>
                                    Assistant Professor, Upstate Medical University<br/>
                                    Syracuse, NY
                                </address>
                            </div>
                            <p className="peer-description">Patients may pay as little as $25 for Solosec when they take advantage of the Solosec Savings Program, so I encourage my patients to download the savings card when I prescribe Solosec.</p>
                        </li>
                        <li className="insights-list-item">
                            <div className="insights-list-wrapper">
                                <img className="peer-image img-fluid" src={jerry} alt="jerry" />
                                <address className="peer-address">
                                    <span className="font-bold">Jerry Ninia, MD</span><br/> 
                                    Director, OB/GYN,<br/>
                                    St Charles Hospital<br/>
                                    Clinical Associate Professor, Stony Brook<br/>
                                    University School of Medicine<br/>
                                    Island OB/GYN<br/>
                                    Port Jefferson Station, NY
                                </address>
                            </div>
                            <p className="peer-description">I prescribe Solosec because it demonstrated statistically significant efficacy in primary and secondary endpoints.</p>
                        </li>
                    </ul>
                    <p className="panel-experts-text"><sup><span className="star">*</span></sup>The panel of experts have been compensated by Lupin Pharmaceuticals, Inc. for participation in this program.</p>
                </div>
            </div>
            <div className="bv-guidelines-wrapper">
                <img className="grey-curve-img" src={greyCurve} alt="greyCurve" />
                <div className="container">
                    <h4 className="bv-guidelines-heading">ACOG adds Solosec<sup>&reg;</sup> (secnidazole) to BV guidelines<sup  className="font-regular">4<span className="star">*</span><span className="cross">†</span></sup></h4>
                    <p className="bv-guidelines-subtext">Secnidazole efficacy comparable to 7-day metronidazole regimen for BV<sup className="font-regular">4</sup></p>
                    <p className="acog-basic-text">ACOG states that the choice of therapy should be individualized based on:</p>
                    <div className="table-responsive">
                        <table className="acog-table table">
                            <tbody>
                                <tr>
                                    <td className="color-yellow font-bold col-left">Patient Preference</td>
                                    <td className="col-right">
                                        <img className="img-fluid" src={checkmarkBold} alt="checkmark" />
                                        <p><span className="color-yellow font-bold">9 out of 10 patients</span><span className="color-white"> who responded to a recent survey</span><span className="color-yellow font-bold"> were satisfied, very satisfied, or extremely satisfied</span> <span className="color-white"> with the result of their Solosec treatment<sup>5‡</sup></span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="color-yellow font-bold col-left">Cost</td>
                                    <td className="col-right">
                                        <img className="img-fluid" src={checkmarkBold} alt="checkmark" />
                                        <p><span className="color-white">Commercially insured patients may</span> <span className="color-yellow font-bold"> pay as little as $25</span> <span className="color-white"> for Solosec with co-pay card<sup>§</sup></span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="color-yellow font-bold col-left">Convenience</td>
                                    <td className="col-right">
                                        <img className="img-fluid" src={checkmarkBold} alt="checkmark" />
                                        <p><span className="color-white">Solosec can be taken</span><span className="color-yellow font-bold"> at any time of day</span><span className="color-white">, without regard to the timing of meals<sup>6</sup></span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="color-yellow font-bold col-left">Adherence</td>
                                    <td className="col-right">
                                        <img className="img-fluid" src={checkmarkBold} alt="checkmark" />
                                        <p><span className="color-white">Solosec delivers a full course of antibiotic therapy in</span><span className="color-yellow font-bold"> just one oral dose<sup>6</sup></span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="color-yellow font-bold col-left">Ease of Use</td>
                                    <td className="col-right">
                                        <img className="img-fluid" src={checkmarkBold} alt="checkmark" />
                                        <p><span className="color-white">Solosec is a single oral dose taken mixed with applesauce, yogurt, or pudding—</span><span className="color-yellow font-bold">no creams or multiple days of pills<sup>4,6,7</sup></span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="color-yellow font-bold col-left">
                                        <p>and History of Response or Adverse Reactions to Previous Treatments<sup>4</sup></p>
                                    </td>
                                    <td className="col-right">
                                        <img className="img-fluid" src={checkmarkBold} alt="checkmark" />
                                        <p className="color-white">In pivotal studies, treatment with Solosec of patients with recurrent BV showed similar efficacy compared to the overall population studied<sup>8,9¶</sup></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="bv-guidelines-data">
                        <p><span><sup>*</sup></span><span>American College of Obstetricians and Gynecologists.</span></p>
                        <p><span><sup>†</sup></span><span>From the January 2020 ACOG Practice Bulletin regarding clinical management guidelines for vaginitis in non-pregnant patients.</span></p>
                        <p><span><sup>‡</sup></span><span>More than 4,000 Solosec kits were distributed to healthcare professionals, including a Solosec voucher, patient brochure, dosing card, and instruction card on how to participate in a brief voluntary survey. 1,888 Solosec vouchers were redeemed and 85 surveys were completed (N=85) in a nonscientific survey conducted by Lupin Pharmaceuticals. Numbers have been rounded.<sup>5</sup></span></p>
                        <p><span><sup>§</sup></span><span>Per fill. Not valid for any person eligible for reimbursement of prescriptions, in whole or in part, by any state or federally funded program, including Medicare, Medigap, or Medicaid, or where prohibited, taxed or otherwise restricted by law. Cash-pay patients may pay as little as $75. Eligibility requirements and terms and conditions apply.</span></p>
                        <p><span><sup>¶</sup></span><span>Study 1 enrolled 215 nonpregnant female patients aged 19-54 years. Efficacy was assessed at the test of cure (TOC) visit after 21-30 days, following initial treatment with a single dose of Solosec.<sup>8</sup> Study 2 enrolled 189 nonpregnant female patients aged 18-54 years. Efficacy was assessed after 7-14 days in an interim visit and at the TOC visit after 21-30 days, following initial treatment with a single dose of Solosec.<sup>9</sup></span></p>
                    </div>
                </div>
            </div>
            <div className="bv-treatment">
                <img className="img-fluid blue-curve-img" src={blueCurve} alt="blueCurve" />
                <div className="container">
                    <h4 className="bv-treatment-heading">Solosec<sup>&reg;</sup> (secnidazole) demonstrated statistically and clinically significant efficacy in the treatment of BV<sup>6,8,9</sup></h4>
                    <div className="row bv-treatment-wrapper">
                        <div className="col-sm-6 bv-treatment-left">
                            <img className="girls-img" src={Girls} alt="Girls" />
                            <img className="girls-text-img" src={GirlsText} alt="GirlsText" />
                            <p className="bv-treatment-text">Efficacy was assessed at the test of cure (TOC) visit after 21-30 days, following initial treatment with a single dose of Solosec.<sup>8</sup></p>
                        </div>
                        <div className="col-sm-6 bv-treatment-right">
                            <img className="clinical-cure-img" src={ClinicalCureRate} alt="ClinicalCureRate" />
                                <p className="bv-treatment-text">
                                    <span>*</span><span>188 women were included in the mITT group. Reasons for exclusion from the mITT group included baseline STIs (n=13 women), Nugent score &lt; 4 at start of study (n=14), and clinically significant baseline laboratory abnormality that led to a diagnosis of chronic myeloid leukemia (n=1). Patients missing &#8805; 1 of the clinical assessments were considered non-responders/not cured.<sup>6,8</sup></span>
                                </p>
                                <p className="bv-treatment-text">
                                    <span className="cross-symbol"><sup>†</sup></span><span>Clinical cure defined as normal vaginal discharge, negative 10% KOH whiff test, and clue cells &lt;20% of total epithelial cells on microscopic examination at the TOC/EOS visit.</span>
                                </p>
                        </div>
                    </div>
                    <a href="https://www.solosechcp.com/bv-treatment#Efficacy?utm_source=relevate&utm_medium=landing-page&utm_campaign=northeast" target="_blank" className="primary-btn">
                        LEARN MORE
                    </a>
                    <p className="bv-treatment-conclusion-text">
                        Microbiologic cure rate (secondary endpoint) in Study 1 was 40.3% for Solosec patients, compared to 6.5% for placebo<sup>8</sup> <br/>Solosec was statistically superior to placebo, even among those women having recurrent infections<sup>8</sup>
                    </p>
                </div>
            </div>
            <section className="alcohol-restriction-section">
                <div className="container">
                    <h4 className="alcohol-restriction-heading">Solosec<sup>&reg;</sup> (secnidazole) has no alcohol restriction; <em>in vitro</em> drug alcohol studies showed it does not interact with aldehyde dehydrogenase<sup>6</sup></h4>
                    <h5 className="alcohol-restriction-sub-heading">Aldehyde dehydrogenase</h5>
                    <p className="aldehyde-text">Other BV medications<sup>10-13</sup></p>
                    <p className="aldehyde-sub-text">
                        Patients warned against concomitant alcohol use (as well as avoidance of foods, drinks, or medicines containing propylene glycol) in order to avoid a potential disulfiram-like reaction characterized by flushing, sweating, nausea, or vomiting<sup>10-13</sup>
                    </p>
                    <div className="aldehyde-img-wrapper">
                        <img className="aldehyde-img" src={Aldehyde} alt="Aldehyde" />
                    </div>
                    <p className="drug-representation-text">Representation of drug interactions with the aldehyde dehydrogenase molecule</p>
                    <p className="mechanisms-text">Watch the video to understand the mechanisms at work:</p>
                    <img onClick={() => { handleShow('alcohol'); }} className="solosec-video-img alcohol-video-img" src={mechanismsVideo} alt="mechanismsVideo" data-toggle="modal" data-target="#exampleModal1" />
                </div>
            </section>
            <section className="one-dose-section">
                <div className="container">
                    <h4 className="one-dose-heading">One Packet. One Dose. One Time.</h4>
                    <p className="one-dose-sub-text">8 out of 10 patients who were surveyed found it easy to take their Solosec<sup>&reg;</sup> (secnidazole) dose<sup>5</sup></p>
                    <div className="row one-dose-wrapper">
                        <div className="col-sm-4 one-dose-col">
                            <img className="number-img" src={One} alt="one" />
                            <img className="process-img mt-10" src={openPacket} alt="openPacket" />
                            <p className="one-dose-text"><span className="color-yellow">Open</span><span className="color-white"> the packet and </span><span className="color-yellow">sprinkle </span><span className="color-white">the entire contents of the packet onto applesauce, yogurt, or pudding<sup>6</sup></span></p>
                        </div>
                        <div className="col-sm-4 one-dose-col">
                            <img className="number-img" src={Two} alt="two" />
                            <img className="process-img" src={stirMix} alt="stirMix" />
                            <p className="one-dose-text"><span className="color-yellow">Stir </span><span className="color-white">and</span><span className="color-yellow"> mix<sup>6</sup></span></p>
                        </div>
                        <div className="col-sm-4 one-dose-col">
                            <img className="number-img" src={Three} alt="three" />
                            <img className="process-img mt-10" src={Consume} alt="Consume" />
                            <p className="one-dose-text consume"><span className="color-yellow">Consume </span><span className="color-white">all of the mixture within 30 minutes. DO NOT CHEW OR CRUNCH THE GRANULES<sup>6</sup></span></p>
                        </div>
                    </div>
                    <div className="drink-water-wrapper">
                        <img className="jar-img" src={Jar} alt="Jar" />
                        <p className="drink-water-text">Patients can drink a glass of water after taking Solosec to help with swallowing, if needed. Do not try to dissolve the medicine in water or any other liquid<sup>6</sup></p>
                    </div>
                    <div className="one-dose-video-wrapper">
                        <p className="one-dose-video-text">Watch the video below to see how the Solosec granules will appear to your patients and are taken mixed with food:</p>
                        <img onClick={() => { handleShow('dosing'); }} className="solosec-video-img solosec-granules-img img-fluid" src={solosecGranules} alt="solosecGranules" />
                    </div>
                </div>
            </section>
            <section className="insured-patients-section">
                <div className="container">
                    <h4 className="insured-patients-heading">Commercially Insured Patients May Pay as Little as $25 for Solosec<sup><span className="star">*</span></sup></h4>
                    <p className="insured-patients-text">*Per fill. Not valid for any person eligible for reimbursement of prescriptions, in whole or in part, by any state- or federally funded program, including Medicare, Medigap, or Medicaid, or where prohibited, taxed or otherwise restricted by law. Cash-pay patients may pay as little as $75. Eligibility requirements and terms and conditions apply. See solosechcp.com/savings-card for full details.</p>
                    <h5 className="top-plans-heading">Top 5 plans that cover Solosec in the Northeast region<sup><span className="cross">†</span></sup></h5>
                    <p className="top-plans-text">Northeast region includes Connecticut; Delaware; Maine; Maryland; Massachusetts; New Hampshire; New Jersey; New York; Pennsylvania; Rhode Island; Vermont; and Washington, DC.</p>
                    <div className="table-responsive">
                        <table role="table" className="plan-table table">
                            <thead role="rowgroup">
                                <tr role="row">
                                    <th role="columnheader" className="plan-name-col">Plan Name</th>
                                    <th role="columnheader" className="formulary-col">Formulary Coverage</th>
                                    <th role="columnheader" className="covered-lives-col">Covered Lives</th>
                                </tr>
                            </thead>
                            <tbody role="rowgroup">
                                <tr role="row">
                                    <td role="cell" className="plan-name-col">Express Scripts Inc</td>
                                    <td role="cell" className="formulary-col">Preferred</td>
                                    <td role="cell" className="covered-lives-col">11,008,562</td>
                                </tr>
                                <tr role="row">
                                    <td role="cell" className="plan-name-col">OPTUMRx</td>
                                    <td role="cell" className="formulary-col">Covered</td>
                                    <td role="cell" className="covered-lives-col">10,362,775</td>
                                </tr>
                                <tr role="row">
                                    <td role="cell" className="plan-name-col">CVS Caremark</td>
                                    <td role="cell" className="formulary-col">Covered</td>
                                    <td role="cell" className="covered-lives-col">10,255,051</td>
                                </tr>
                                <tr role="row">
                                    <td role="cell" className="plan-name-col">Prime Therapeutics LLC Unaffiliated</td>
                                    <td role="cell" className="formulary-col">Covered</td>
                                    <td role="cell" className="covered-lives-col">9,786,498</td>
                                </tr>
                                <tr role="row">
                                    <td role="cell" className="plan-name-col">CIGNA HealthCare (Corporate)</td>
                                    <td role="cell" className="formulary-col">Preferred</td>
                                    <td role="cell" className="covered-lives-col">9,465,191</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <a href="https://www.solosechcp.com/savings-card?utm_source=relevate&utm_medium=landing-page&utm_campaign=northeast" target="_blank" className="primary-btn learn-more-btn">
                        LEARN MORE
                    </a>
                    <div className="insured-patients-conclusion">
                        <p><span className="cross-symbol"><sup>†</sup></span>Data Source: Business One Technologies August 2020.</p>
                        <p>Check your formulary for specific information as not all plans in your area may cover Solosec. This information is intended for provider communication only. This information is not a guarantee of coverage or payment (partial or full). Actual benefits are determined by each plan administrator in accordance with its respective policy and procedures.</p>
                    </div>
                </div>
            </section>
            <section className="patients-survey-section">
                <div className="container">
                    <h4 className="patients-survey-heading">What survey patients said about Solosec<sup>&reg;</sup> (secnidazole)<sup>5<span className="star">*</span></sup></h4>
                    <h5 className="patients-survey-sub-heading"><span className="color-yellow">9 out of 10 </span><span className="color-white">patients who responded to the survey<sup>5*</sup>:</span></h5>
                    <img className="img-fluid survey-img" src={surveyImg1} alt="surveyImg1" />
                    <div className="review-comparision-wrapper">
                        <div className="review-comparision-item review-comparision-left">
                            <img className="img-fluid checkmark" src={checkmark} alt="checkmark" />
                            <p className="review-comparision-text">
                                <span className="color-white">Were</span><span className="color-yellow"> satisfied, very satisfied, or extremely<br/> satisfied</span><span className="color-white"> with the result <br/>of their Solosec treatment<sup>5</sup></span>
                            </p>
                        </div>
                        <div className="review-comparision-item review-comparision-right">
                            <img className="img-fluid checkmark" src={checkmark} alt="checkmark" />
                            <p className="review-comparision-text">
                            <span className="color-white">Would</span><span className="color-yellow"> recommend to a friend</span><span className="color-white"> with <br/>BV that they speak to their doctor <br/>about Solosec<sup>5</sup></span>
                            </p>
                        </div>
                    </div>
                    <h5 className="patients-survey-sub-heading"><span className="color-white">And</span><span className="color-yellow"> 8 out of 10 </span><span className="color-white">patients would request Solosec again if diagnosed with BV in the future<sup>5*</sup></span></h5>
                    <img className="img-fluid survey-img" src={surveyImg2} alt="surveyImg2" />
                    <p className="solosec-kits-text">*More than 4,000 Solosec kits were distributed to healthcare professionals, including a Solosec voucher, patient brochure, dosing card, and instruction card on how to participate in a brief voluntary survey. 1,888 Solosec vouchers were redeemed and 85 surveys were completed (N=85) in a nonscientific survey conducted by Lupin Pharmaceuticals. Numbers have been rounded.<sup>5</sup></p>
                    <h6 className="insights-video-heading">Solosec Patient and KOL Insights<sup><span className="cross">†</span></sup></h6>
                    <img onClick={() => { handleShow('testimonial'); }} className="solosec-video-img img-fluid insights-video-thumbnail" src={insightsVideo} alt="insights-video-thumbnail" />
                    <p className="patients-survey-conclusion-text"><span className="cross-symbol"><sup>†</sup></span>Video features an actual Solosec patient who was compensated for her services and time. Individual results may differ.</p>
                </div>
            </section>
			<div className="reference-wrapper">
				<div className="container">
                    <div className="ref-list-wrapper">
                        <h3 className="ref-heading">REFERENCES:</h3>
                        <ol className="ref-data-list">
                            <li>Data on File – IQVIA Monthly &amp; Weekly TRx Data in SMART, U.S. Edition – Weekly and Monthly NPA Data (Assessed as of June 2020). Prepared June 2020.</li>
                            <li>Koumans EH, Sternberg M, Bruce C, et al. The prevalence of bacterial vaginosis in the United States, 2001-2004; associations with symptoms, sexual behaviors, and reproductive health. <em>Sex Transm Dis.</em> 2007;34(11):864-869. doi: 10.1097/OLQ.0b013e318074e565.</li>
                            <li>State population by characteristics: 2010-2019. Annual estimates of the civilian population by single year of age and sex for the United States: April 1, 2010 to July 1, 2018 (SCPRC-EST2019-18+POP-RES). US Census Bureau. Updated March 2, 2020. https://www.census.gov/data/tables/time-series/demo/popest/2010s-state-detail.html. Accessed April 20, 2020.</li>
                            <li>American College of Obstetricians and Gynecologists. ACOG Practice Bulletin: Clinical Management Guidelines for Obstetricians-Gynecologists, Number 215. <em>Obstet Gynecol.</em> 2020:135(1):e1-e17.</li>
                            <li>Data on file. Experience Program: Post Treatment Patient Survey data (May 2019-Sep 2019). Prepared September 2019. (LPSO-23506). Lupin Pharmaceuticals, Inc.</li>
                            <li>SOLOSEC [prescribing information]. Baltimore, MD: Lupin Pharmaceuticals, Inc.; 2017.</li>
                            <li>Data on File, Physician Research. Advantage Healthcare, Inc. Prepared December 23, 2014.</li>
                            <li>Hillier SL, Nyirjesy P, Waldbaum AS, et al. Secnidazole treatment of bacterial vaginosis: a randomized controlled trial. <em>Obstet Gynecol.</em> 2017;130(2):379-386.</li>
                            <li>Schwebke JR, Morgan FG Jr, Koltun W, Nyirjesy P. A phase-3, double-blind, placebo-controlled study of the effectiveness and safety of single oral doses of secnidazole 2 g for the treatment of women with bacterial vaginosis [published correction appears in <em>Am J Obstet Gynecol.</em> 2018;219(1):110]. <em>Am J Obstet Gynecol.</em> 2017;217(6):678.e1-678.e9.</li>
                            <li>Flagyl Extended Release [prescribing information]. Chicago, IL: G.D. Searle LLC; 2018.</li>
                            <li>Tinidazole Tablets [prescribing information]. Buffalo Grove, IL: G.D. PACK Pharmaceuticals, LLC; 2013.</li>
                            <li>Metrogel [prescribing information]. Sellersville, PA: Teva Pharmaceuticals USA; 2005.</li>
                            <li>Nuvessa [prescribing information]. Madrid, Spain: Chemo Research SL; 2017.</li>
                        </ol>
                    </div>
					<div className="additional-ref-wrapper mb-10">
						<h3 className="additional-ref-heading">Indication</h3>
						<p className="additional-ref-data">
							SOLOSEC<sup>&reg;</sup> (secnidazole) 2 g oral granules is a 5-nitroimidazole antimicrobial agent indicated for the treatment of bacterial vaginosis in adult women.
						</p>
					</div>
					<div className="additional-ref-wrapper mb-10">
						<h3 className="additional-ref-heading">Dosage and Administration</h3>
						<p className="additional-ref-data">
							SOLOSEC is a single-dose therapy for oral use. The entire contents of SOLOSEC packet should be sprinkled onto applesauce, yogurt or pudding and consumed once within 30 minutes without
	chewing or crunching the granules. SOLOSEC is not intended to be dissolved in any liquid.
						</p>
					</div>
					<div className="additional-ref-wrapper mb-10">
						<h3 className="additional-ref-heading">Important Safety Information</h3>
						<ul className="additional-ref-data additional-ref-data-list">
							<li>SOLOSEC is contraindicated in patients with a history of hypersensitivity to secnidazole, other ingredients of the formulation, or other nitroimidazole derivatives.</li>
							<li>Vulvo-vaginal candidiasis may develop with SOLOSEC and require treatment with an antifungal agent.</li>
							<li>Potential risk of carcinogenicity is unknown and has not been studied. Carcinogenicity has been seen in rodents chronically treated with nitroimidazole derivatives, which are structurally related to secnidazole. Chronic use should be avoided.</li>
							<li>Breastfeeding is not recommended. Patients should discontinue breastfeeding for 96 hours after administration of SOLOSEC.</li>
							<li>Most common adverse reactions observed in clinical trials (incidence ≥2%) were vulvovaginal candidiasis, headache, nausea, dysgeusia, vomiting, diarrhea, abdominal pain, and vulvovaginal pruritus.</li>
						</ul>
					</div>
					<p className="contact-info mb-10">To report SUSPECTED ADVERSE REACTIONS, contact Lupin Pharmaceuticals, Inc. at 1-844-SOLOSEC (1-844-765-6732) or FDA at 1-800-FDA-1088 or <a className="reporting-link" target="_blank" href="https://www.fda.gov/medwatch">www.fda.gov/medwatch.</a></p>
					<p className="contact-info mb-10">Please ask your Lupin Women’s Healthcare Representative for full Prescribing Information, also available at <a href="https://www.solosechcp.com/?utm_source=relevate&utm_medium=landing-page&utm_campaign=northeast" target="_blank">solosechcp.com</a>.</p>
				</div>
			</div>
		</main>
		<footer>
			<div className="container">
				<div className="footer-top d-flex justify-content-center">
                    <img className="img-fluid" src={landing_footer_swoosh} alt="landing-footerSwoosh" />
				</div>
				<div className="footer-bottom">
					<img className="footer-logo-img" src={logo_lupin} alt="logo-lupin" />
					<p className="copyright-text">
						&copy; 2020 Lupin Pharmaceuticals, Inc. All rights reserved.
						Solosec<sup>&reg;</sup> is a registered trademark owned by Lupin Inc.
                        <br/>
						PP-SOL-0560 Last updated 9/20
					</p>
				</div>
			</div>
		</footer>
        <SolosecVideoModal
            modalOpen={show}
            close={handleClose}
        />
	</div>
    )
}